<!-- components/HamburgerMenu.vue -->
<template>
  <div class="news-memu-container theme-2">
    <div class="news-menu-row">
      <div class="hamburger" @click="toggleMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
          fill="none">
          <rect width="40" height="40" fill="white" fill-opacity="0.01" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.25 10.3333C6.25 9.41282 6.99619 8.66663 7.91667 8.66663H31.25C32.1705 8.66663 32.9167 9.41282 32.9167 10.3333C32.9167 11.2538 32.1705 12 31.25 12H7.91667C6.99619 12 6.25 11.2538 6.25 10.3333ZM6.25 20.3333C6.25 19.4128 6.99619 18.6666 7.91667 18.6666H24.5833C25.5038 18.6666 26.25 19.4128 26.25 20.3333C26.25 21.2538 25.5038 22 24.5833 22H7.91667C6.99619 22 6.25 21.2538 6.25 20.3333ZM7.91667 28.6666C6.99619 28.6666 6.25 29.4128 6.25 30.3333C6.25 31.2538 6.99619 32 7.91667 32H31.25C32.1705 32 32.9167 31.2538 32.9167 30.3333C32.9167 29.4128 32.1705 28.6666 31.25 28.6666H7.91667Z"
            fill="black" />
        </svg>
      </div>
      <div class="site-name">
        OMG Tech
      </div>
    </div>

    <transition name="slide-fade">
      <div v-show="isOpen" class="menu">
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import '@/css/news/menu.scss';

export default {
  data () {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
